import React from "react";
import { FaClock, FaChurch, FaCamera, FaUtensils, FaMusic, FaBirthdayCake } from "react-icons/fa";
import "./EventInfo.css";
import { IoRoseSharp } from "react-icons/io5";

const Roadmap = ({ isCelebration }) => (
  <div className="roadmap-container">
    <div className="roadmap">
      <h2>Agenda de la Boda</h2>

      <ul className="timeline">
        {!isCelebration && (
          <li className="timeline-item">
            <div className="icon"><IoRoseSharp size={32} color="#680517" /></div>
            <div className="title">Recepción</div>
            <div className="hour">3:30 PM</div>
            <div className="activity">Ceremonia</div>
          </li>
        )}
        <li className="timeline-item">
          <div className="icon"><FaClock size={32} color="#680517" /></div>
          <div className="title">Recepción</div>
          <div className="hour">6:00 PM</div>
          <div className="activity">Recepción del salón</div>
        </li>
        <li className="timeline-item">
          <div className="icon"><FaUtensils size={32} color="#680517" /></div>
          <div className="title">Cena</div>
          <div className="hour">7:00 PM</div>
          <div className="activity">Cena en el salón de eventos</div>
        </li>
        <li className="timeline-item">
          <div className="icon"><FaMusic size={32} color="#680517" /></div>
          <div className="title">Tornaboda</div>
          <div className="hour">Media Noche</div>
          <div className="activity">Baile y entretenimiento</div>
        </li>
      </ul>
    </div>
  </div>
);

export default Roadmap;
