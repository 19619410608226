import React from "react";
import { FaUser } from "react-icons/fa";
import parents from "../assets/images/parents.png";
import "./EventInfo.css";

const ParentsInfo = () => (
  <>
    <div className="event-info">
      <div className="event-details">
        <img src={parents} alt="Event Logo" className="event-logo" />
        <h2>Padres de los Novios</h2>
        <div className="parent-info">
          <div className="parent">
            <div className="parent-names">
              <h3 className="names">Padres de Mariana</h3>
              <p>
                <span>Martha Sofia Santana Luevano</span>
                <br />
                <span>César Ivan Riveros González</span>
              </p>
            </div>
          </div>
          <div className="parent">
            <div className="parent-names">
              <h3 className="names">Padres de Jorge</h3>
              <p>
                <span>Ana Maria del Carmen González Burgos</span>
                <br />
                <span>Jorge Luis Bravo Panta</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ParentsInfo;
