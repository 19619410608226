import React from "react";
import { FaHome, FaUser, FaEnvelope, FaPhone } from "react-icons/fa";
import hotel from "../assets/images/hotel.png";

import "./EventInfo.css";

const DateAndTime = ({ date, time }) => (
  <div className="date-and-time">
    <div className="date">{date}</div>
    <div className="divider" />
    <div className="time">{time}</div>
  </div>
);

const Button = ({ children, url }) => (
  <button className="button" onClick={() => window.open(url, "_blank")}>
    {children}
  </button>
);

const AccommodationInfo = () => {
  const hotelName = "Hotel Mansion Inn";
  const hotelPhoneNumber = "+52 713 116 7827";
  const hotelPrice = "980.00";
  const hotelDescription = "Habitación doble o sencilla";

  const googleMapsUrl = "https://maps.app.goo.gl/sG5MF9xcfyHtcxFm6";

  return (
    <>
      <img src={hotel} alt="Event Logo" className="hotel-icon" />
      <h2>Hospedaje</h2>
      <div className="contact-info">
        <div className="contact-item">
          <p className="contact-text">Hospedaje Cercano:</p>
          <p className="contact-text">{hotelName}</p>
          <p className="contact-text">
            <FaPhone /> {hotelPhoneNumber}
          </p>
          <p className="contact-text">
            {hotelPrice} MXN habitación doble o sencilla
          </p>
        </div>
      </div>
      <Button url={googleMapsUrl}>VER UBICACIÓN</Button>
    </>
  );
};

export default AccommodationInfo;
