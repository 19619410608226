import React, { useState } from "react";
import axios from "axios";
import "./EventInfo.css";
import "./Form.css";

const Button = ({ children, onClick }) => (
  <button className="button" onClick={onClick}>
    {children}
  </button>
);

const Confirmation = ({ openModal, isCelebration }) => {
  const [attendees, setAttendees] = useState('');
  const [message, setMessage] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");

  const handleAttendeesChange = (event) => {
    let value = event.target.value;
  
    // Permitir borrar si el campo está vacío o si se está cambiando a un valor válido
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 2)) {
      setAttendees(value);
    }
  };
  

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAttendanceChange = (event) => {
    setAttendanceStatus(event.target.value);
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append('asiste', attendanceStatus);
    data.append('nombre', document.getElementById("name").value);
    data.append('cantidad_asistentes', attendees);
    data.append('mensaje', message);
    data.append('isCelebration', isCelebration);

    axios.post("https://jorgeymarianabackend.vercel.app/recibir_invitacion/", data)
      .then(response => {
        openModal("Tu respuesta ha sido recibida, ¡muchas gracias!");
      })
      .catch(error => {
        openModal("Ha ocurrido un error, inténtalo de nuevo más tarde.");
        console.error("Error enviando la invitación:", error.message);
      });
  };

  return (
    <>
      <div className="event-info">
        <div className="form-info">
          <div className="event-details">
            <h2>CONFIRMAR ASISTENCIA</h2>
            <div className="confirmation-message">
              Por favor, confirma tu asistencia
            </div>
            <hr className="divider" />
            <div className="confirmation-form">
              <form>
                <div className="attendance-options">
                  <div className="form-group">
                    <input
                      type="radio"
                      id="attendance-yes"
                      name="attendance-status"
                      value="yes"
                      checked={attendanceStatus === "yes"}
                      onChange={handleAttendanceChange}
                    />
                    <label htmlFor="attendance-yes">
                      ¡Sí, no me lo pierdo!
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="radio"
                      id="attendance-no"
                      name="attendance-status"
                      value="no"
                      checked={attendanceStatus === "no"}
                      onChange={handleAttendanceChange}
                    />
                    <label htmlFor="attendance-no">
                      ¡No podré asistir, lo siento!
                    </label>
                  </div>
                </div>
                <label htmlFor="name">Nombre:</label>
                <input type="text" id="name" name="name" />
                <label htmlFor="attendance">
                  Cantidad de asistentes:
                </label>
                <input
                  type="text"
                  id="attendance"
                  name="attendance"
                  value={attendees}
                  onChange={handleAttendeesChange}
                />
                <label htmlFor="message">Escribe tu mensaje:</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
              </form>
            </div>
          </div>
          <Button onClick={handleSubmit}>Enviar Respuesta</Button>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
