import React from "react";
import "./Gallery.css"; // Asegúrate de tener estilos CSS adecuados
import photo1 from "../assets/images/photos/1.jpg";
import photo2 from "../assets/images/photos/2.jpg";
import photo3 from "../assets/images/photos/3.jpg";
import photo4 from "../assets/images/photos/4.jpg";
import photo5 from "../assets/images/photos/5.jpg";
import photo6 from "../assets/images/photos/6.jpg";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="gallery-item">
        <img
          src={photo1}
          alt="Gallery Item 1"
          className="image"
        />
      </div>
      <div className="gallery-item">
        <img
          src={photo2}
          alt="Gallery Item 2"
          className="image"
        />
      </div>
      <div className="gallery-item">
        <img
          src={photo3}
          alt="Gallery Item 3"
          className="image"
        />
      </div>
      <div className="gallery-item">
        <img
          src={photo4}
          alt="Gallery Item 4"
          className="image"
        />
      </div>
      <div className="gallery-item">
        <img
          src={photo5}
          alt="Gallery Item 5"
          className="image"
        />
      </div>
      <div className="gallery-item">
        <img
          src={photo6}
          alt="Gallery Item 6"
          className="image"
        />
      </div>
    </div>
  );
};

export default Gallery;

