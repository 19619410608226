import React from "react";
import dresscode from "../assets/images/clothes.png";
import "./EventInfo.css";

const DressCode = () => (
  <>
    <div className="event-info">
      <div className="event-details">
        <img src={dresscode} alt="Event Logo" className="event-logo" />
        <h2>DRESSCODE</h2>
        <p>Vestimenta Formal</p>
        <div className="address">El Color Vino y Blanco está reservado para las damas y la novia</div>
      </div>
    </div>
  </>
);

export default DressCode;
