import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Countdown from "./components/Countdown";
import ContactInfo from "./components/ContactInfo";
import DressCode from "./components/Dresscode";
import Gallery from "./components/Gallery";
import Gifts from "./components/Gifts";
import GiftsPeru from "./components/GiftsPeru";
import Form from "./components/Form";
import Roadmap from "./components/Roadmap";
import ParentsInfo from "./components/ParentsInfo";
import AccommodationInfo from "./components/AccommodationInfo";
import ConfirmationModal from "./components/ConfirmationModal";
import FadeInContainer from "./components/FadeInContainer";
import logo from "./assets/images/logo.png";
import logowhite from "./assets/images/whitelogo.png";
import text from "./assets/images/TEXT.png";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/Celebracion" element={<CelebrationComponent />} />
          <Route path="/" element={<HomeComponent />} />
          <Route path="/PE" element={<PeruvianComponent />} />

        </Routes>
      </Router>
    </div>
  );
}

function HomeComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };
  const isCelebration = false
  return (
    <div className="App">
      <header className="App-header title">
        <img src={logowhite} alt="Event Logo" className="main-text" />

        <img src={text} alt="Event Logo" className="main-text" />
        <h2>¡NUESTRA BODA!</h2>
      </header>
      <div className="main">
        <FadeInContainer>
          <Countdown />
        </FadeInContainer>
        <FadeInContainer>
          <ContactInfo
            eventName="CEREMONIA & CELEBRACIÓN"
            address="Salón Regina"
            date="31 MAY"
            time="15:30 h."
            googleMapsUrl="https://www.google.com.mx/maps/place/Sal%C3%B3n+Jard%C3%ADn+Regina/@19.1918622,-99.4734621,17z/data=!3m1!4b1!4m6!3m5!1s0x85cdf3cf7b551c85:0x6f4203bc0a63e097!8m2!3d19.1918572!4d-99.4708872!16s%2Fg%2F11b6j5j6n8?entry=ttu"
          />
        </FadeInContainer>
        <FadeInContainer>
          <Roadmap isCelebration={isCelebration} />
        </FadeInContainer>
        <FadeInContainer>
          <DressCode />
        </FadeInContainer>
        <FadeInContainer>
          <ParentsInfo />
        </FadeInContainer>

        <FadeInContainer>
          <Gallery />
        </FadeInContainer>
        <FadeInContainer>
          <Gifts />
        </FadeInContainer>
        <FadeInContainer>
          <AccommodationInfo />
        </FadeInContainer>
        <FadeInContainer>
          <Form openModal={handleOpenModal} isCelebration={isCelebration} />
        </FadeInContainer>

        {showModal && (
          <ConfirmationModal
            message={modalMessage}
            closeModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
}

function CelebrationComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const isCelebration = true

  return (
    <div className="App">
      <header className="App-header title">
        <img src={logowhite} alt="Event Logo" className="" />
        <img src={text} alt="Event Logo" className="main-text" />
        <h2>¡NUESTRA BODA!</h2>
      </header>
      <div className="main">
        <FadeInContainer>
          <Countdown />
        </FadeInContainer>
        <FadeInContainer>
          <ContactInfo
            eventName="CELEBRACIÓN"
            address="Salón Regina"
            date="31 MAY"
            time="18:00 h."
            googleMapsUrl="https://www.google.com.mx/maps/place/Sal%C3%B3n+Jard%C3%ADn+Regina/@19.1918622,-99.4734621,17z/data=!3m1!4b1!4m6!3m5!1s0x85cdf3cf7b551c85:0x6f4203bc0a63e097!8m2!3d19.1918572!4d-99.4708872!16s%2Fg%2F11b6j5j6n8?entry=ttu"
          />
        </FadeInContainer>
        <FadeInContainer>
          <Roadmap isCelebration={isCelebration} />
        </FadeInContainer>
        <FadeInContainer>
          <DressCode />
        </FadeInContainer>
        <FadeInContainer>
          <ParentsInfo />
        </FadeInContainer>

        <FadeInContainer>
          <Gallery />
        </FadeInContainer>
        <FadeInContainer>
          <Gifts />
        </FadeInContainer>
        <FadeInContainer>
          <AccommodationInfo />
        </FadeInContainer>
        <FadeInContainer>
          <Form openModal={handleOpenModal} isCelebration={isCelebration} />
        </FadeInContainer>

        {showModal && (
          <ConfirmationModal
            message={modalMessage}
            closeModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
}



function PeruvianComponent() {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const isCelebration = false

  return (
    <div className="App">
      <header className="App-header title">
        <img src={logowhite} alt="Event Logo" className="" />
        <img src={text} alt="Event Logo" className="main-text" />
        <h2>¡NUESTRA BODA!</h2>
      </header>
      <div className="main">
        <FadeInContainer>
          <Countdown />
        </FadeInContainer>
        <FadeInContainer>
          <ContactInfo
            eventName="CELEBRACIÓN"
            address="Salón Regina"
            date="31 MAY"
            time="18:00 h."
            googleMapsUrl="https://www.google.com.mx/maps/place/Sal%C3%B3n+Jard%C3%ADn+Regina/@19.1918622,-99.4734621,17z/data=!3m1!4b1!4m6!3m5!1s0x85cdf3cf7b551c85:0x6f4203bc0a63e097!8m2!3d19.1918572!4d-99.4708872!16s%2Fg%2F11b6j5j6n8?entry=ttu"
          />
        </FadeInContainer>
        <FadeInContainer>
          <Roadmap isCelebration={isCelebration} />
        </FadeInContainer>
        <FadeInContainer>
          <DressCode />
        </FadeInContainer>
        <FadeInContainer>
          <ParentsInfo />
        </FadeInContainer>

        <FadeInContainer>
          <Gallery />
        </FadeInContainer>
        <FadeInContainer>
          <GiftsPeru />
        </FadeInContainer>
        <FadeInContainer>
          <AccommodationInfo />
        </FadeInContainer>
        <FadeInContainer>
          <Form openModal={handleOpenModal} isCelebration={isCelebration} />
        </FadeInContainer>

        {showModal && (
          <ConfirmationModal
            message={modalMessage}
            closeModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
}
export default App;
