import React, { useState } from "react";
import gift from "../assets/images/gift-box.png";
import "./EventInfo.css";
import "./PE.css";

const GiftsPE = () => {
  const [copied, setCopied] = useState(false);

  const peruAccountNumber = "8983129286262"; // Cuenta Simple Dólares en Interbank
  const peruAccountCCI = "00389801312928626243"; // CCI en Interbank

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <div className="event-info">
        <div className="event-details">
          <img src={gift} alt="Event Logo" className="event-logo" />
          <h2>REGALOS</h2>
          <div className="address">
            Lo más importante es tu presencia, pero si deseas hacernos un regalo aquí tienes algunas opciones.
            Para extranjeros de Perú que deseen contribuir, pueden depositar en la siguiente cuenta:
            <br />
            Banco: Interbank<br />
            Número de cuenta: {peruAccountNumber}<br />
            CCI: {peruAccountCCI}
          </div>
        </div>
        <div className="buttons">
          <button className="button" onClick={() => copyToClipboard(peruAccountNumber)}>
            Copiar Cuenta Simple Dólares
          </button>
          <button className="button" onClick={() => copyToClipboard(peruAccountCCI)}>
            Copiar Cuenta CCI
          </button>
        </div>
      </div>
      {copied && <div className="copied-msg">¡Se ha copiado el número!</div>}
    </>
  );
};

export default GiftsPE;
