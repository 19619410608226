import React, { useEffect } from "react";
import gifRings from "../assets/images/GIFRINGS.gif";
import "./EventInfo.css";

const DateAndTime = ({ date, time }) => (
  <div className="date-and-time">
    <div className="date">{date}</div>
    <div className="divider" />
    <div className="time">{time}</div>
  </div>
);

const Button = ({ children, url }) => (
  <button className="button" onClick={() => window.open(url, "_blank")}>
    {children}
  </button>
);

const ContactInfo = ({ eventName, address, date, time, googleMapsUrl }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAfkC6mlun7janbnxHaCtCvf_M6WaifmtU&callback=initMap`;
    script.async = true;
    script.defer = true;
    script.addEventListener("load", initMap);
    document.head.appendChild(script);

    return () => {
      // Limpiar el evento load del script para evitar problemas de memoria
      script.removeEventListener("load", initMap);
    };
  }, []);

  const initMap = () => {
    const location = { lat: 19.192080558021317, lng: -99.47083861263448 };
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 15,
      center: location,
    });
    new window.google.maps.Marker({
      position: location,
      map: map,
      title: "Event Location",
    });
  };

  return (
    <>
      <div className="event-info">
        <div className="event-details">
          <img src={gifRings} alt="Event Logo" className="event-logo" />
          <h2>{eventName}</h2>
        </div>
      </div>
      <DateAndTime date={date} time={time} />
      <div className="contact-info">
        <div className="contact-item">
          <p className="contact-text">{address}</p>
        </div>
      </div>
      <div className="map-container" style={{ width: "50%", margin: "0 auto" }}>
        <div id="map" className="map" style={{ height: "400px", width: "100%" }}></div>
      </div>
      <Button url={googleMapsUrl}>VER UBICACIÓN</Button>
    </>
  );
};

export default ContactInfo;
