import React, { useState, useEffect } from "react";
import './main.css';
const CountdownTimer = () => {
  // Our state to hold the time values
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // The target date for the countdown
  const targetDate = new Date("2024-05-31T00:00:00");

  // Use the useEffect hook to start the countdown
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutesLeft = Math.floor((diff / (1000 * 60)) % 60);
      const secondsLeft = Math.floor((diff / 1000) % 60);

      // Update the state with the new time values
      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div style={containerStyle}>
      <div className="title-cursive">¡Estas Invitado!</div>
      <p>
        Nos encantaría que seas parte de este momento tan especial para
        nosotros. ¡Falta poco!
      </p>
      <div style={timeRowContainerStyle}>
        <TimeCircle value={days} label="DÍAS" />
        <TimeCircle value={hours} label="HORAS" />
        <TimeCircle value={minutes} label="MINUTOS" />
        <TimeCircle value={seconds} label="SEGUNDOS" />
      </div>
    </div>
  );
};

const TimeCircle = ({ value, label }) => (
    <div style={timeCircleContainer}>
      <div className="timer" style={timeCircleStyle}>{value}</div>
      <div className="timer" style={timeLabelStyle}>{label}</div>
    </div>
  );
  

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
};


const timeRowContainerStyle = {
  display: "flex",
  gap: '1.7em'
};

const timeCircleContainer = {
  textAlign: "center",
};

const timeCircleStyle = {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "#680517",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.3rem", // Reducir el tamaño de la fuente
    color: "white",
    minWidth: "60px", // Ajuste para asegurar un ancho mínimo
  };
  
  const timeLabelStyle = {
    marginTop: "0.5rem", // Reducir el margen superior
    color: "#666",
    display: "flex",
    justifyContent: "center",
    width: "60px",
    fontSize: "0.8rem", // Reducir el tamaño de la fuente
  };
  
  

export default CountdownTimer;
