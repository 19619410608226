import React from "react";
import gift from "../assets/images/gift-box.png";
import liverpool from "../assets/images/liverpool.png";
import "./EventInfo.css";

const Button = ({ children, url }) => (
  <button className="button" onClick={() => window.open(url, "_blank")}>
    {children}
  </button>
);
const LiverPoolUrl = "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51307273";

const Gifts = () => (
  <>
    <div className="event-info">
      <div className="event-details">
        <img src={gift} alt="Event Logo" className="event-logo" />
        <h2>REGALOS</h2>
        <div className="address">Lo más importante es tu presencia, pero si deseas hacernos un regalo aquí tienes algunas opciones</div>
      </div>
      <img src={liverpool} alt="Event Logo" className="liverpool" />
      <Button url={LiverPoolUrl}>Ver lista de regalos (Liverpool)</Button>
    </div>
  </>
);

export default Gifts;
